<template>
    <v-card
        v-if="isDataLoading || dataError"
        class="card-shadow border-radius-xl"
    >
        <div class="card-header-padding">
            <h5 v-if="header" class="font-weight-bold text-h5 text-typo mb-0">
                {{ header }}
            </h5>
            <v-progress-circular
                v-if="isDataLoading && !dataError"
                class="d-block mx-auto my-4"
                indeterminate
                :size="48"
                :width="7"
            ></v-progress-circular>
            <p v-else class="text-sm text-red font-weight-light mb-0">
                {{ $t('datatable.loadingError') }}
            </p>
        </div>
    </v-card>

    <v-card v-else class="card-shadow border-radius-xl">
        <div v-if="header || subheader" class="card-header-padding">
            <h5 v-if="header" class="font-weight-bold text-h5 text-typo mb-0">
                {{ header }}
            </h5>
            <p
                v-if="subheader"
                class="text-sm text-body font-weight-light mb-0"
            >
                {{ subheader }}
            </p>
        </div>

        <v-card-text class="px-0 py-0">
            <v-data-table
                :headers="headers"
                :items="data"
                class="table"
                hide-default-footer
                density="compact"
                mobile-breakpoint="0"
                :itemsPerPage="limit"
                :no-data-text="$t('datatable.noDataAvailable')"
            >
                <!-- Slots for custom data display -->
                <template v-slot:item="{ item }">
                    <tr>
                        <td
                            v-for="(
                                columnValue, key
                            ) in filterPropertiesIncludedInTableHeaders(item)"
                            :key="key"
                        >
                            <slot :name="key" :item="item">
                                {{ columnValue }}
                            </slot>
                        </td>
                    </tr>
                </template>
                <!--  -->
                <template v-slot:footer>
                    <slot name="footer"></slot>
                </template>
            </v-data-table>
        </v-card-text>
    </v-card>
</template>
<script>
export default {
    name: 'datatable-basic',
    props: {
        data: {
            type: Array,
            required: false,
        },
        tableSettings: {
            type: Object,
            required: true,
        },
        isDataLoading: {
            type: Boolean,
            required: true,
        },
        dataError: {
            type: Boolean,
            required: true,
        },
        header: {
            type: String,
            required: false,
        },
        subheader: {
            type: String,
            required: false,
        },
        limit: {
            type: Number,
            required: false,
            default: 10,
        },
    },
    data() {
        return {
            headers: this.tableSettings.headers,
        };
    },
    computed: {
        headerValues() {
            return this.headers.map((header) => {
                return header.value;
            });
        },
    },
    methods: {
        filterPropertiesIncludedInTableHeaders(item) {
            return Object.keys(item)
                .filter((key) => this.headerValues.includes(key))
                .reduce((newObject, key) => {
                    newObject[key] = item[key];
                    return newObject;
                }, {});
        },
    },
};
</script>
