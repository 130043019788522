<template>
    <v-container fluid class="px-6 py-6">
        <v-row class="mb-12">
            <v-col md="6">
                <datatable-basic
                    :limit="10"
                    :data="serversData"
                    :tableSettings="serversTableSettings"
                    :isDataLoading="isServersDataLoading"
                    :dataError="serversDataError"
                    :header="$t('servers.table.header')"
                >
                    <template #last24h_status="{ item }">
                        {{ item.last24h_status }}%
                    </template>
                    <template #footer>
                        <div class="pa-4">
                            <router-link to="/pages/servers">
                                <v-btn
                                    color="indigo"
                                    class="text-white"
                                    append-icon="construction"
                                >
                                    {{ $t('dashboard.seeAllServers') }}
                                    <v-icon
                                        size="28"
                                        class="ml-3 material-icons-round"
                                        >chevron_right</v-icon
                                    >
                                </v-btn>
                            </router-link>
                        </div>
                    </template>
                </datatable-basic>
            </v-col>
            <v-col md="6">
                <datatable-basic
                    :limit="10"
                    :data="domainsData"
                    :tableSettings="domainsTableSettings"
                    :isDataLoading="isDomainsDataLoading"
                    :dataError="domainsDataError"
                    :header="$t('domains.table.header')"
                >
                    <template #last24h_status="{ item }">
                        {{ item.last24h_status }}%
                    </template>
                    <template #footer>
                        <div class="pa-4">
                            <router-link to="/pages/domains">
                                <v-btn
                                    color="indigo"
                                    class="text-white"
                                    append-icon="construction"
                                >
                                    {{ $t('dashboard.seeAllDomains') }}
                                    <v-icon
                                        size="28"
                                        class="ml-3 material-icons-round"
                                        >chevron_right</v-icon
                                    >
                                </v-btn>
                            </router-link>
                        </div>
                    </template>
                </datatable-basic>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import DatatableBasic from '@/components/Tables/DatatableBasic.vue';

import { getDomain, getServer } from '@/api';

export default {
    name: 'Domains',
    components: {
        DatatableBasic,
    },
    data() {
        return {
            domainsData: null,
            isDomainsDataLoading: true,
            domainsDataError: false,
            serversData: null,
            isServersDataLoading: true,
            serversDataError: false,
            domainsTableSettings: {
                headers: [
                    {
                        text: this.$t('domains.table.tableHeader.name'),
                        align: 'start',
                        cellClass: 'border-bottom',
                        value: 'name',
                        class: 'text-secondary font-weight-bolder opacity-7 border-bottom ps-6',
                    },
                    {
                        text: this.$t('domains.table.tableHeader.ip'),
                        value: 'ip',
                        class: 'text-secondary font-weight-bolder opacity-7',
                    },
                    {
                        text: this.$t('domains.table.tableHeader.status'),
                        value: 'current_status',
                        class: 'text-secondary font-weight-bolder opacity-7',
                        width: '90px',
                    },
                    {
                        text: this.$t('domains.table.tableHeader.last24h'),
                        value: 'last24h_status',
                        class: 'text-secondary font-weight-bolder opacity-7',
                        sortable: false,
                        width: '130px',
                    },
                ],
            },
            serversTableSettings: {
                headers: [
                    {
                        text: this.$t('servers.table.tableHeader.host'),
                        align: 'start',
                        cellClass: 'border-bottom',
                        value: 'host',
                        class: 'text-secondary font-weight-bolder opacity-7 border-bottom ps-6',
                    },
                    {
                        text: this.$t('servers.table.tableHeader.ip'),
                        value: 'ip',
                        class: 'text-secondary font-weight-bolder opacity-7',
                    },
                    {
                        text: this.$t('servers.table.tableHeader.type'),
                        value: 'type',
                        class: 'text-secondary font-weight-bolder opacity-7',
                    },
                    {
                        text: this.$t('servers.table.tableHeader.status'),
                        value: 'current_status',
                        class: 'text-secondary font-weight-bolder opacity-7',
                        width: '90px',
                    },
                    {
                        text: this.$t('servers.table.tableHeader.last24h'),
                        value: 'last24h_status',
                        class: 'text-secondary font-weight-bolder opacity-7',
                        sortable: false,
                        width: '130px',
                    },
                ],
            },
        };
    },
    methods: {
        getDomain: async function () {
            try {
                this.isDomainsDataLoading = true;
                this.domainsData = await getDomain();

                if (
                    process.env.NODE_ENV === 'development' &&
                    process.env.VUE_APP_API_DEBUG === 'true'
                ) {
                    console.log('API Action: Loaded domains');
                }
            } catch (error) {
                this.domainsDataError = true;
                console.error(error);
            } finally {
                this.isDomainsDataLoading = false;
            }
        },
        getServer: async function () {
            try {
                this.isServersDataLoading = true;
                this.serversData = await getServer();

                if (
                    process.env.NODE_ENV === 'development' &&
                    process.env.VUE_APP_API_DEBUG === 'true'
                ) {
                    console.log('API Action: Loaded servers');
                }
            } catch (error) {
                this.serversDataError = true;
                console.error(error);
            } finally {
                this.isServersDataLoading = false;
            }
        },
    },
    mounted: async function () {
        await Promise.all([this.getDomain(), this.getServer()]);
    },
};
</script>
